import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/inputQr",
    name: "InputQr",
    children:[
      {
        path: "promotionId-:promotionId",
        name: "StudentList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import("../views/StudentList.vue"),
      },
      {
        path: "promotionId-:promotionId/schoolMateId-:schoolMateId",
        name: "ShowStudent",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import("../views/ShowStudent.vue"),
      },
      {
        path: "promotionId-:promotionId/schoolMateId-:schoolMateId/edit",
        name: "EditStudent",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import("../views/EditStudent.vue"),
      },
      {
        path: "promotionId-:promotionId/add",
        name: "AddStudent",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import("../views/AddStudent.vue"),
      },
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import("../views/InputQr.vue"),
  },
  {
    path: "/dataRepair",
    name: "dataRepair",
    component: () =>
        import("../views/DataRepair.vue"),
  },
  {
    path: "/statResult",
    name: "statResult",
    component: () =>
        import("../views/StatResult.vue"),
  },
  {
    path: "/ORList",
    name: "ORList",
    component: () =>
        import("../views/OphthalmologyRegistrationList.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
