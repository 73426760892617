<template>
  <nav-bar/>
  <router-view/>
</template>

<script>
import NavBar from "@/views/NavBar";
import qs from "qs";
import axios from "axios";
export default {
  name: 'App',
  components: {
    NavBar,
  },
  mounted() {
    let i= this;
    let req = {token:this.$store.state.requestToken.token}
    axios.post("https://www.wanmeishili.com/admin/getDefaultSchoolPlanByAdminId.json",qs.stringify(req))
        .then(
          (res)=>{
            let requestToken = {
              schoolKeyword: '',
              schoolPlanId: res.data.json.result.object.id,
              schoolLevelId: '',
              index: 0,
              length: 100,
              token: i.$store.state.requestToken.token
            };
            i.$store.commit('changeRequestToken',requestToken);
            i.$store.commit('updateCurrentPlanName',res.data.json.result.object.planName);

        })
        .catch((err)=>{
          console.log(err)
        });
    // console.log(this.$store.state.requestToken)
  },
}



</script>

<style>

</style>
