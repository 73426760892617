import { createStore } from "vuex";

export default createStore({
  state: {
    requestToken:{
      token:'29427ec5-fbeb-4db7-9b51-4b3092a79d35',
    },
    planName:null,
    loading:false,
    currentClass:{},
  },
  getters: {},
  mutations: {
    changeRequestToken(state,val){
      state.requestToken = val
    },
    changeLoadingStatus(state,val){
      state.loading = val
    },
    updateCurrentClass(state,val){
      state.currentClass = val
    },
    updateCurrentPlanName(state,val){
      state.planName = val
    }
  },
  actions: {},
  modules: {},
});
