<template>
  <router-link :to="featureButtonLink"
               class="hover:border-green-600 h-22 rounded border flex flex-col items-center justify-between py-1">
    <slot></slot>
    <p>{{buttonName}}</p>
  </router-link>
</template>

<script>
export default {
  props:{
    featureButtonLink:String,
    buttonName:String,
  },
  name: "FeatureButton"
}
</script>

<style scoped>

</style>