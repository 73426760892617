<template>
  <div class="px-2 py-1 flex justify-between items-center">
    <span>当前筛查计划：</span>
    <p class="text-sm text-orange-600">{{$store.state.planName}}</p>
    <div class="dropdown dropdown-left">
      <label tabindex="0" @click="selectPlan" class="hover:cursor-pointer text-blue-600 border-blue-600 border-b">切换计划</label>
      <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-64">
        <div v-if="loading"><loading-spinner/></div>
        <div v-else>
          <li v-for="item in planList" :key="item.id" ><a>{{item.planName}}</a></li>
        </div>

      </ul>
    </div>
  </div>
  <div class="grid grid-cols-3 gap-2 p-4">
    <feature-button button-name="筛查二维码" feature-button-link="/inputQr">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
      </svg>
    </feature-button>
    <feature-button button-name="筛查数据更正" feature-button-link="/dataRepair">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
        <path d="M3 12v3c0 1.657 3.134 3 7 3s7-1.343 7-3v-3c0 1.657-3.134 3-7 3s-7-1.343-7-3z" />
        <path d="M3 7v3c0 1.657 3.134 3 7 3s7-1.343 7-3V7c0 1.657-3.134 3-7 3S3 8.657 3 7z" />
        <path d="M17 5c0 1.657-3.134 3-7 3S3 6.657 3 5s3.134-3 7-3 7 1.343 7 3z" />
      </svg>
    </feature-button>
    <feature-button button-name="筛查报表" feature-button-link="/statResult">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
      </svg>
    </feature-button>
<!--    <feature-button/>-->
<!--    <feature-button/>-->
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import FeatureButton from "@/components/FeatureButton";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: 'HomeView',
  components: {
    FeatureButton,LoadingSpinner
  },
  data(){
    return{
      planList:null,
      requestToken:this.$store.state.requestToken,
      loading : true,
    }
  },
  methods:{
    selectPlan:function(){
      let req = {
        keyword:"",
        status: 0,
        index: 0,
        length: 10,
        token: this.requestToken.token
      };
      let i = this;
      this.loading = true;
      axios.post("https://www.wanmeishili.com/admin/selectSchoolPlanList.json",qs.stringify(req))
          .then(
              (res)=>{
                  i.planList = res.data.json.result.list;
                  i.loading = false;
              }
          )
          .catch((err)=>{
              console.log(err)
          });
    },
  }

}
</script>

<style scoped>

</style>