<template>
  <div class="flex justify-center py-2">
    <div class="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
}
</script>

<style scoped>

</style>